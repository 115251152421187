import React, { ReactElement, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import RootStore from '../../store/Root'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { FileCheckFill, PersonFill, PeopleFill, List, PersonCircle, People, LayoutTextWindowReverse, FileEarmarkTextFill, Spellcheck, ListCheck, Paragraph, InfoLg, CheckAll, EyeFill, RecordCircle, QuestionCircleFill, ShieldShaded, TagsFill, HCircleFill, FileEarmarkPdfFill, Tablet, CircleHalf, ExclamationTriangleFill, BroadcastPin, KeyFill, BoxArrowRight, EnvelopeFill, Speedometer2, BoxFill, Search, Receipt, CashCoin, X, FileMusic, PencilSquare, Database, Building, BuildingGear, PinMap } from 'react-bootstrap-icons'
import { USER_ROLE_ADMIN_CMS, USER_ROLE_ADMIN_CMS_TEAM_IPAD, USER_ROLE_DEED_ONLY, USER_ROLE_ORG_ADMIN, USER_ROLE_SUPER_ADMIN, USER_ROLE_TEAM_CMS_IPAD } from '../../utils/constant'

interface Props {
    rootStore: RootStore
}

interface SideBarLink {
    path: string,
    text: string,
    icon: ReactElement<any, any>,
    role: Array<string>,
    onClick?: (() => void)
}

const superAdminUser = [
    USER_ROLE_SUPER_ADMIN,
    USER_ROLE_ADMIN_CMS_TEAM_IPAD
]

const superAdminOnly = [
    USER_ROLE_SUPER_ADMIN
]

const orgAdminOnly = [
    USER_ROLE_ORG_ADMIN
]

const superAdminsAndOrgAdmin = [
    ...superAdminUser,
    USER_ROLE_ORG_ADMIN,
]

const allUser = [
    ...superAdminUser,
    USER_ROLE_ADMIN_CMS,
    USER_ROLE_TEAM_CMS_IPAD,
    USER_ROLE_ORG_ADMIN
]

const allUserWithDeedOnly = [...allUser, USER_ROLE_DEED_ONLY, USER_ROLE_ORG_ADMIN]
console.log('orgId', localStorage.getItem('userOrg'))
const SideBar: React.FC<Props> = ({ rootStore }) => {

    const { searchTerm } = rootStore.navStore

    const doLogout = () => {
        rootStore.authStore.logout()
    }
    const sidebarLinks: SideBarLink[] = [
        { path: '/dashboard', text: 'Dashboard', icon: <Speedometer2  />, role: allUserWithDeedOnly },
        { path: '/app-users/', text: 'User', icon: <PersonFill />, role: superAdminsAndOrgAdmin },
        { path: '/organizations/', text: 'Organization', icon: <Building />, role: superAdminOnly },
        { path: `/organizations/settings/${localStorage.getItem('userOrg')}`, text: 'Organization Settings', icon: <BuildingGear />, role: orgAdminOnly },
        { path: '/addresses/', text: 'Office Address', icon: <PinMap />, role: superAdminUser },
        { path: '/clients/', text: 'Client', icon: <PeopleFill />, role: allUser },
        { path: '/states/', text: 'State', icon: <List />, role: superAdminUser },
        { path: '/fields/', text: 'Field', icon: <LayoutTextWindowReverse />, role: superAdminUser },
        { path: '/packages/', text: 'Package', icon: <BoxFill />, role: superAdminUser },
        { path: '/templates/', text: 'Template', icon: <FileEarmarkTextFill />, role: superAdminUser },
        { path: '/plurals/', text: 'Manage Plural', icon: <Spellcheck />, role: superAdminUser },
        { path: '/conditions/', text: 'Condition', icon: <ListCheck />, role: superAdminUser },
        { path: '/common-paragraphs/', text: 'Common Paragraph', icon: <Paragraph />, role: superAdminUser },
        { path: '/trustee-info/', text: 'Trustee Info', icon: <InfoLg />, role: superAdminsAndOrgAdmin },
        { path: '/deed-research/', text: 'Deed Research', icon: <Search />, role: allUserWithDeedOnly },
        { path: '/deed-filing/', text: 'Deed Filing', icon: <FileCheckFill />, role: allUser },
        { path: '/matters/', text: 'Matter', icon: <CheckAll />, role: allUser },
        { path: '/client-draft-adjustments/', text: 'Client Draft Adjustment', icon: <PencilSquare />, role: allUser },
        { path: '/probables/', text: 'Probable', icon: <People />, role: superAdminUser },
        { path: '/visions/', text: 'Vision', icon: <EyeFill />, role: allUser },
        { path: '/invoice/', text: 'Invoice', icon: <Receipt />, role: superAdminsAndOrgAdmin },
        { path: '/plans/', text: 'Vision - Plan', icon: <RecordCircle />, role: superAdminUser },
        { path: '/plan-pricing-templates/', text: 'Vision - Plan Pricing Template', icon: <CashCoin />, role: superAdminUser },
        { path: '/problem-questions/', text: 'Vision - Estate Planning Problem Question', icon: <QuestionCircleFill />, role: superAdminUser },
        { path: '/estate-protect-plans/', text: 'Vision - Estate Protect Plan', icon: <ShieldShaded />, role: superAdminUser },
        { path: '/engagement-templates', text: 'Vision - Engagement Template', icon: <FileEarmarkTextFill />, role: superAdminUser },
        { path: '/recordings', text: 'Vision - Recordings', icon: <FileMusic />, role: superAdminUser },
        { path: '/tags/', text: 'Tag', icon: <TagsFill />, role: superAdminUser },
        { path: '/state-specific-hc-info/', text: 'State-specific HC Info', icon: <HCircleFill />, role: superAdminUser },
        { path: '/settings/style-pdf', text: 'PDF Style', icon: <FileEarmarkPdfFill />, role: superAdminUser },
        { path: '/settings/app', text: 'iPad App Settings', icon: <Tablet />, role: superAdminUser },
        { path: '/oath/daylite', text: 'Connect to Daylite', icon: <CircleHalf />, role: superAdminUser },
        { path: '/daylite-cron-details', text: 'Daylite Cron', icon: <BroadcastPin />, role: superAdminUser },
        { path: '/error-logs/', text: 'Error Logs', icon: <ExclamationTriangleFill />, role: superAdminUser },
        { path: '/email-logs/', text: 'Email Logs', icon: <EnvelopeFill />, role: superAdminUser },
        { path: '/third-party-logs/', text: 'Third Party Log', icon: <ExclamationTriangleFill />, role: superAdminUser},
        { path: '/oath/xero', text: 'Connect to Xero', icon: <CircleHalf />, role: superAdminUser },
        { path: '/oath/lawmatics', text: 'Connect to Lawmatics', icon: <CircleHalf />, role: superAdminUser },
        { path: '/lawmatics-cron-details', text: 'Lawmatics Cron', icon: <BroadcastPin />, role: superAdminUser },
        { path: '/fetched-ipad-local-data/', text: 'iPad Data', icon: <Database />, role: superAdminUser },
        { path: '/settings/profile/view', text: 'View Profile', icon: <PersonCircle />, role: allUserWithDeedOnly },
        { path: '/settings/password/edit', text: 'Change Password', icon: <KeyFill className='rotate-45' />, role: allUserWithDeedOnly },
        { path: '/login', text: 'Sign Out', icon: <BoxArrowRight />, onClick: doLogout, role: allUserWithDeedOnly}
    ]

    const [sidebarList, setSidebarList] = useState<SideBarLink[]>(sidebarLinks)

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        rootStore.navStore.setSearchTerm(e.target.value)
    }

    useEffect(() => {
        // Filter links based on search term and user role
        const filteredLinks = sidebarLinks.filter(link => {
            const matchesSearchTerm = link.text.toLowerCase().includes(searchTerm.toLowerCase())
            const currentUserRole = localStorage.getItem('userRole') || ''
            const isVisibleToUser = (link.role?.includes(currentUserRole) || (link.role?.includes(currentUserRole) && rootStore.authStore.isAdmin))
            return matchesSearchTerm && isVisibleToUser
        })
        setSidebarList(filteredLinks)
    }, [searchTerm])

    return <>
        <nav className={classNames('col-md-3 col-lg-2 d-md-block bg-light sidebar collapse', { 'show': rootStore.navStore.collapse })}>
            <div className='sidebar-sticky'>
                <div className='search-wrapper'>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => handleSearchChange(e)}
                        className="sidebar-search"
                    />
                    <X size={20} onClick={() => rootStore.navStore.setSearchTerm('')} className={classNames('search-cancel-icon', { 'd-none': searchTerm.length === 0 })} />
                </div>
                <ul className='nav flex-column'>
                    {sidebarList.length === 0 && (
                        <h6 className='text-center p-2'>No Result Found</h6>
                    )}
                    {sidebarList.map((link, index) => {
                        return <li key={index} className='nav-item'>
                            <NavLink className="nav-link " to={link.path} activeClassName='active' onClick={link.onClick ? link.onClick : () => null}>
                                {link.icon} &nbsp;&nbsp; {link.text}
                            </NavLink>
                        </li>
                    })}
                </ul>
            </div>
        </nav>
    </>
}

export default observer(SideBar)
