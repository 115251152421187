import React, { useState, DragEvent, ChangeEvent, useEffect } from 'react'
import { NewDeedImage } from '../../store/types.d'
import { observer } from 'mobx-react'
import uuid from 'react-uuid'
import { toast } from 'react-toastify'
import { TableLoader } from '../commonComponent/Loader'
import RootStore from '../../store/Root'
import { formatBytes } from '../commonComponent/commonFuction'
import { addDeedFile } from '../../api/DeedImagesAction'
import { Download, Trash } from 'react-bootstrap-icons'
import Table from '../table/Table'
import { Cell } from '../table/TableCellText.d'
import { FormateDateWithTime } from '../commonComponent/TableCell'
import { Link } from 'react-router-dom'


interface ImageUploaderProps {
    rootStore: RootStore,
    UUID: string
}

const DeedResearchFilesUploader: React.FC<ImageUploaderProps> = ({ rootStore, UUID}) => {
    const { singleDocDeedImagesStore } = rootStore
    const { images } = singleDocDeedImagesStore
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        if(singleDocDeedImagesStore.UUID === '' || singleDocDeedImagesStore.UUID !== UUID) {
            singleDocDeedImagesStore.setUUID(UUID)
        }
        if (singleDocDeedImagesStore.isImagesFetched === false) {
            singleDocDeedImagesStore.resetImages()
            singleDocDeedImagesStore.getDeedFiles()
        }
    }, [UUID, singleDocDeedImagesStore])

    useEffect(() => {
        const hash = window.location.hash
        if (hash === '#deed-images') {
            const element = document.getElementById('deed-images')
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }, [])

    const addNewFileInList = async (newImages: NewDeedImage[]) => {
        singleDocDeedImagesStore.setLoading(true)
        singleDocDeedImagesStore.setProcessStatus(false)
        const totalImages = newImages.length
        let addedImages = 0
        await new Promise(() => {
            for (let i = 0; i < newImages.length; i++) {
                const reqData = {
                    image: newImages[i],
                    UUID: UUID
                }
                addDeedFile(reqData).then((addResponse) => {
                    if (i === newImages.length - 1) {
                        singleDocDeedImagesStore.setProcessStatus(true)
                    }
                    addedImages = addedImages + 1
                    if(addResponse.data.success === 0) {
                        toast.error(`${addedImages}/${totalImages} image uploaded`, { position: 'top-right'})
                    } else {
                        toast.success(`${addedImages}/${totalImages} image uploaded`, { position: 'top-right'})
                        singleDocDeedImagesStore.getDeedFiles()
                        rootStore.deedResearchStore.fetchDeeds(rootStore.deedResearchStore.page, rootStore.deedResearchStore.searchItem, rootStore.deedResearchStore.filterWithUserId, rootStore.deedResearchStore.filterWithFutureSigning, rootStore.deedResearchStore.searchByStatus, rootStore.deedResearchStore.excludeTestDeeds, rootStore.deedResearchStore.filterWithState) 
                    }
                }).catch((error) => {
                    toast.error(`Error in upload images: ${error.message}`, { position: 'top-right'})
                })
            }
        })
    }

    const handleFiles = (files: File[]) => {
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']
        const newImages: NewDeedImage[] = []
        setError(null)
        const fileReaders = files.map((file) => {
            return new Promise<void>((resolve) => {
                if (file.type && file.type !== '' && allowedTypes.includes(file.type)) {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    
                    reader.onloadend = () => {
                        if (reader.result) {
                            newImages.push({
                                src: (file.type === 'application/pdf') ? reader.result?.toString().split(',')[1] : reader.result as string,
                                id: uuid(),
                                file_type: file.type,
                                display_order: images.length + newImages.length,
                                original_file_name: file.name
                            })
                        }
                        resolve()
                    }
                } else {
                    setError('Only PNG, JPEG, JPG, and PDF files are allowed.')
                    resolve()
                }
            })
        })

        Promise.all(fileReaders).then(async () => {
            if (newImages.length !== 0) {
                await addNewFileInList(newImages)
            }
        })
    }
        
    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.currentTarget.style.cursor = 'grab'
        e.preventDefault()
        const files = Array.from(e.dataTransfer.files)
        handleFiles(files)
    }

    const handleBrowse = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files ? Array.from(e.target.files) : []
        handleFiles(files)
    }

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.currentTarget.style.cursor = 'grabbing'
        e.preventDefault()
    }

    const handleRemove = (id:string, index: number) => {
        const sure = confirm('Are you sure you want to remove this file ?')
        if (sure) {
            singleDocDeedImagesStore.removeImage(id, index, 'deed_research', (res) => {
                if (res.success) {
                    toast.success(res.message, { position: 'top-right'})
                } else {
                    toast.error(res.message, { position: 'top-right'})
                }
            })
        }
    }

    const downloadFile = async (filePath: string, original_file_name = '') => {
        const defaultFileName = filePath.split('/').pop()
        if (defaultFileName !== undefined) {
            singleDocDeedImagesStore.setLoading(true)
            const response = await fetch(filePath)
      
            if (!response.ok) {
                setError('Failed to fetch file')
            }

            const blob = await response.blob()

            // Create a URL for the Blob and use it to create an anchor element
            const blobUrl = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = blobUrl
      
            link.setAttribute('download', original_file_name || defaultFileName)
            document.body.appendChild(link)
            link.click()
            window.URL.revokeObjectURL(blobUrl)
            document.body.removeChild(link)
            singleDocDeedImagesStore.setLoading(false)
        }
    }

    const TableCellAction: React.FC<Cell> = (props) => {
        const { id, index } = props.data

        return <>
            <Download title='Download File' className='cursor-pointer mr-3' onClick={async () => await downloadFile(props.data.image_key, props.data.original_file_name)} />{ rootStore.authStore.isDeedUser === false && <Trash title='Delete File' className='cursor-pointer' onClick={() => handleRemove(id, index)} />}
        </>
    }

    const openPDF = (pdfLink) => {
        window.open(pdfLink, '_blank')
    }

    const TableCellView: React.FC<Cell> = (props) => {
        const { image_key, size } = props.data

        return <>
            <Link to='#' className='cursor-pointer' title={formatBytes(size)} onClick={() => openPDF(image_key)}>{props.value}</Link>
        </>
    }

    const latestImages = singleDocDeedImagesStore.getImages

    return (
        <div className="deed-image-main mb-3" id='deed-images'>
            { singleDocDeedImagesStore.isLoading && <TableLoader isLoading={singleDocDeedImagesStore.isLoading} />} 
            <div className="image-uploader mt-3">
                <div
                    className="drop-zone"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                >
                        Drag & Drop files here or &nbsp; <input type="file" id='fileInput' className='custom-file-input' placeholder=' ' multiple accept=".png,.jpeg,.jpg,.pdf" onChange={handleBrowse} /><label style={{marginBottom:'0', border: '1px solid gray', padding: '2px 4px', borderRadius:'4px', backgroundColor: '#ebebeb'}} className='cursor-pointer' htmlFor='fileInput'>Choose File</label>
                </div>
                {error && <p className="error-message">{error}</p>}
                {latestImages.length !== 0 &&<div className='table-responsive table-sm'>
                    <div className=''>
                        <Table
                            unique_key='_id'
                            columns={[
                                { name: 'original_file_name', title: 'File Name', component: TableCellView },
                                { name: 'uploaded_by', title: 'Uploaded By' },
                                { name: 'uploaded_at', title: 'Uploaded At', component: FormateDateWithTime },
                                { name: 'id', title: 'Action', component: observer(TableCellAction) }
                            ]}
                            data={latestImages}
                        ></Table>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default observer(DeedResearchFilesUploader)